import { useTheme } from 'vuetify'
import { Chart, ChartComponentLike } from 'chart.js'

export default function useCharts(...items: ChartComponentLike[]) {
  Chart.register(...items)
  const themeColors = useTheme().computedThemes.value.customLightTheme.colors
  Chart.defaults.backgroundColor = themeColors.background
  Chart.defaults.color = themeColors['on-background']
  Chart.defaults.plugins.datalabels = {
    backgroundColor: themeColors.background,
    color: themeColors['on-background'],
    borderRadius: 3,
    textAlign: 'center',
    clip: false,
  }
}
