<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref, watch } from 'vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import UpdateStep from '@/components/step/UpdateStep.vue'
import CreateStep from '@/components/step/CreateStep.vue'
import StepTree from '@/components/step/StepTree.vue'
import {
  ModuleViewDataQuery,
  ModuleViewDataQueryVariables,
  VersionStatus,
} from '@/generated/graphql'
import { authzIsOrgMbom } from '@/app'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import DownloadModuleMbomButton from '@/components/mbom/DownloadModuleMbomButton.vue'
import ViewStep from '@/components/step/ViewStep.vue'
import ViewCheckConfigurations from '@/components/checkConfiguration/ViewCheckConfigurations.vue'
import DuplicateStep from '@/components/step/DuplicateStep.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import StepProposalsFromFileDialog from '@/components/step/StepProposalsFromFileDialog.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const productId = computed(() => route.params.productId as string)
const moduleId = computed(() => route.params.moduleId as string)
const stepId = computed(() => route.params.stepId as string | undefined)
const bopId = computed(() => route.params.bopId as string)
const siteId = computed(() => route.params.siteId as string)
const configId = computed(() => route.params.productConfigId as string)

const fetchQuery = useQuery<ModuleViewDataQuery, ModuleViewDataQueryVariables>(
  gql`
    query ModuleViewData($bopId: ID!, $moduleId: ID!) {
      product {
        bop(id: $bopId) {
          id
          versionStatus
        }
        module(id: $moduleId) {
          id
          name
          abbreviation
        }
      }
    }
  `,
  () => ({
    bopId: bopId.value,
    moduleId: moduleId.value,
  }),
)
const isReleased = computed(
  () => fetchQuery.result.value?.product.bop.versionStatus == VersionStatus.Released,
)
const canMbom = computed(() => isReleased.value && authzIsOrgMbom.value)
const module = computed(() => fetchQuery.result.value?.product.module)

type State = 'initial' | 'update' | 'create' | 'view'
const state = ref<State>('initial')
function createNode() {
  state.value = 'create'
}
watch(
  [stepId, isReleased],
  (v) => {
    if (v) {
      state.value = isReleased.value ? 'view' : 'update'
    }
  },
  { immediate: true },
)

const stepTree = ref<ComponentExposed<typeof StepTree>>()
const refresh = () => {
  fetchQuery.refetch()
  stepTree.value?.refresh()
}

const onStepCreated = (id: string) => {
  refresh()
  router.push({ params: { stepId: id } })
}
const onStepDeleted = () => {
  router.push({ name: 'module', params: { stepId: undefined } })
  refresh()
  state.value = 'initial'
}

const duplicateStepDialog = ref<ComponentExposed<typeof DuplicateStep>>()
const doDuplicate = async () => {
  duplicateStepDialog.value?.open()
}
const onDuplicated = (id: string) => {
  refresh()
  router.push({ params: { stepId: id } })
}

const proposalsFromFileDialog = ref<ComponentExposed<typeof StepProposalsFromFileDialog>>()
const doProposeFromMedia = async () => {
  proposalsFromFileDialog.value?.open()
}
</script>

<template>
  <breadcrumb />
  <template v-if="canMbom">
    <download-module-mbom-button
      v-if="!!module"
      :bop-id="bopId"
      :module="module"
      short
      variant="elevated"
      color="primary"
      style="position: absolute; right: 1em; top: 6em"
    />
  </template>
  <v-row class="mt-1">
    <v-col cols="6" xl="5" xxl="4">
      <step-tree
        ref="stepTree"
        :bop-id="bopId"
        :module-id="moduleId"
        :selected-step-id="stepId"
        @create-node="createNode"
        @copy-node="doDuplicate"
        @propose-from-media="doProposeFromMedia"
        @select-step-id="onStepCreated"
      />
    </v-col>
    <v-col>
      <div>
        <v-empty-state v-if="state == 'initial'" icon="account_tree" text="Select a step" />
        <create-step
          v-if="state == 'create'"
          :site-id="siteId"
          :module-id="moduleId"
          :product-id="productId"
          @created="onStepCreated"
        />
        <update-step
          v-if="!!stepId && state == 'update'"
          :step-id="stepId"
          :site-id="siteId"
          :module-id="moduleId"
          :bop-id="bopId"
          @updated="refresh"
          @deleted="onStepDeleted"
        />
        <duplicate-step
          ref="duplicateStepDialog"
          :product-id="productId"
          :target-bop-id="bopId"
          :target-module-id="moduleId"
          :initial-site-id="siteId"
          :initial-config-id="configId"
          :initial-step-id="stepId"
          @duplicated="onDuplicated"
        />
        <step-proposals-from-file-dialog
          ref="proposalsFromFileDialog"
          :product-id="productId"
          :module-id="moduleId"
          :site-id="siteId"
          @created="onStepCreated"
        />
        <template v-if="!!stepId && state == 'view'">
          <view-step :step-id="stepId" />

          <h2 class="mt-10">{{ t('entity.checkConfiguration.plural') }}</h2>
          <view-check-configurations :step-id="stepId" />
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss"></style>
