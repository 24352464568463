<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  BopModulesTimeChartDataQuery,
  BopModulesTimeChartDataQueryVariables,
} from '@/generated/graphql'
import { computed, onUnmounted, ref, watch } from 'vue'
import { ArcElement, Chart, PieController, Tooltip, TooltipItem } from 'chart.js'
import { secondsToHumanReadable } from '@/app'
import { useI18n } from 'vue-i18n'
import { useHexColorGradients } from '@/useColorGradients'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import useCharts from '@/composables/useCharts'

const props = defineProps<{
  bopId: string
  height: number
}>()

const { t } = useI18n()

const fetchQuery = useQuery<BopModulesTimeChartDataQuery, BopModulesTimeChartDataQueryVariables>(
  gql`
    query BopModulesTimeChartData($bopId: ID!) {
      product {
        bop(id: $bopId) {
          nodes {
            module {
              id
              name
              nodes {
                id
                step {
                  id
                  name
                  durationInSeconds
                }
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    bopId: props.bopId,
  }),
)
type LocalModule = BopModulesTimeChartDataQuery['product']['bop']['nodes'][0]['module']
type ModuleTime = {
  module: LocalModule
  totalSeconds: number
}
const moduleTimes = computed<ModuleTime[]>(
  () =>
    fetchQuery.result.value?.product.bop.nodes
      .map((n) => ({
        module: n.module,
        totalSeconds: n.module.nodes.reduce((acc, n) => acc + n.step.durationInSeconds, 0),
      }))
      .filter((mt) => mt.totalSeconds > 0)
      .toSorted((a, b) => a.totalSeconds - b.totalSeconds) || [],
)

useCharts(PieController, ArcElement, ChartDataLabels, Tooltip)
const canvas = ref<HTMLCanvasElement>()
const chart = ref<Chart<'doughnut'>>()
const resetChart = () => {
  if (chart.value) {
    chart.value.destroy()
    chart.value = undefined
  }
}
onUnmounted(() => resetChart())

watch([canvas, moduleTimes], () => {
  if (!canvas.value || moduleTimes.value.length == 0) {
    return
  }

  resetChart()

  const options = {
    aspectRatio: 2,
    backgroundColor: useHexColorGradients(moduleTimes.value.length),
    plugins: {
      tooltip: {
        callbacks: {
          label: (t: TooltipItem<'doughnut'>) => secondsToHumanReadable(t.parsed),
        },
      },
      datalabels: {
        formatter: (value: number, context: any) =>
          context.chart.data.labels[context.dataIndex] + ': ' + secondsToHumanReadable(value),
      },
    },
  }
  chart.value = new Chart(canvas.value, {
    type: 'doughnut',
    options,
    data: {
      labels: moduleTimes.value.map((mt) => mt.module.name),
      datasets: [
        {
          data: moduleTimes.value.map((mt) => mt.totalSeconds),
        },
      ],
    },
  })
})
</script>

<template>
  <div v-if="moduleTimes.length == 0" class="text-center mt-3 mb-3" style="font-size: 0.9em">
    {{ t('component.dataTable.noData') }}
  </div>
  <div v-if="moduleTimes.length > 0" :style="`height: ${props.height}px`" class="mx-auto">
    <canvas ref="canvas" v-bind="$attrs" />
  </div>
</template>

<style scoped lang="scss"></style>
