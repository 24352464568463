<script setup lang="ts" generic="MM">
import { StepPickerDataQuery, StepPickerDataQueryVariables } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const model = defineModel<string | undefined>()
const props = defineProps<{
  moduleId?: string
  label?: string
}>()

const { t } = useI18n()

const label = computed(() => props.label || t('entity.step.singular'))

watch(
  () => props.moduleId,
  () => {
    model.value = undefined
  },
)
const disabled = computed(() => !props.moduleId)

type StepPickerNode = StepPickerDataQuery['product']['module']['nodes'][0]
const fetchQuery = useQuery<StepPickerDataQuery, StepPickerDataQueryVariables>(
  gql`
    query StepPickerData($moduleId: ID!) {
      product {
        module(id: $moduleId) {
          abbreviation
          nodes {
            id
            parentId
            step {
              id
              name
              durationInSeconds
              instructionText
              stepType {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    moduleId: props.moduleId as string,
  }),
  () => ({
    enabled: !disabled.value,
  }),
)
const module = computed(() => fetchQuery.result.value?.product.module)
const nodes = computed(() => fetchQuery.result.value?.product.module.nodes || [])
const options = computed<SelectOption<string>[]>(() => {
  if (disabled.value) {
    return []
  }

  let newOptions: SelectOption<string>[] = []
  nodes.value
    .filter((n) => !n.parentId)
    .forEach((n, i) => addNodeAndChildren(i, [module.value?.abbreviation || ''], n, newOptions))
  return newOptions
})
function addNodeAndChildren(
  index: number,
  parentNumbering: string[],
  node: StepPickerNode,
  options: SelectOption<string>[],
) {
  const ancestryNumbering = [...parentNumbering, `${index + 1}`]

  options.push({
    title: `${ancestryNumbering.join('.')} ${node.step.name}`,
    value: node.step.id,
  })
  nodes.value
    .filter((n) => n.parentId == node.id)
    .forEach((n, i) => addNodeAndChildren(i, ancestryNumbering, n, options))
}
</script>

<template>
  <select-field
    v-model="model"
    :label="label"
    :items="options"
    :loading="fetchQuery.loading.value"
    :disabled="disabled"
  />
</template>

<style scoped lang="scss"></style>
