<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import useAsyncDialog from '@/components/dialogs/useAsyncDialog'
import StepProposalsConverter from '@/components/step/StepProposalsConverter.vue'
import {
  StepProposal,
  StepProposalResult,
  StepProposalsFromMediaMimeTypesQuery,
  StepProposalsFromMediaMimeTypesQueryVariables,
} from '@/generated/graphql'
import TextField from '@/components/input/TextField.vue'
import UploadPicker, { PickedUpload } from '@/components/upload/UploadPicker.vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import AsyncConfirmDialog from '@/components/dialogs/AsyncConfirmDialog.vue'
import AsyncDialog from '@/components/dialogs/AsyncDialog.vue'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import StepProposalsFromPdfLoader from '@/components/step/StepProposalsFromPdfLoader.vue'
import StepProposalsFromMediaLoader from '@/components/step/StepProposalsFromMediaLoader.vue'

const props = defineProps<{
  productId: string
  moduleId: string
  siteId: string
}>()
const emit = defineEmits<{
  created: []
}>()

const { t } = useI18n()
const { openAsyncDialog, asyncDialogResolve, asyncDialogLoading, asyncDialogIsOpen } =
  useAsyncDialog<() => Promise<void>>()

enum STEP {
  FILE = 1,
  LOAD = 2,
  CONVERT = 3,
}
const stepperModel = ref<STEP>(STEP.FILE)
const isComplete = (currentStep: STEP) => {
  return stepperModel.value > currentStep
}

const errorDialog = ref<ComponentExposed<typeof AsyncConfirmDialog>>()
const errors = ref<string[]>([])
watch(errors, (v) => {
  if (v.length > 0) {
    errorDialog.value?.open(async () => {
      errors.value = []
    }, {})
  }
})

const mediaMimeTypesQuery = useQuery<
  StepProposalsFromMediaMimeTypesQuery,
  StepProposalsFromMediaMimeTypesQueryVariables
>(gql`
  query StepProposalsFromMediaMimeTypes {
    product {
      createStepProposalsFromMediaSupportedMimeTypes
    }
  }
`)
const mimeTypes = computed(() => {
  const all =
    mediaMimeTypesQuery.result.value?.product.createStepProposalsFromMediaSupportedMimeTypes || []
  all.push('application/pdf')

  return all
})

const uploadPickerOpen = ref(false)
const pickedUpload = ref<PickedUpload>()
watch(asyncDialogIsOpen, (v) => {
  if (v) {
    pickedUpload.value = undefined
  }
})
watch(pickedUpload, (v) => {
  if (v) {
    stepperModel.value = STEP.LOAD
  }
})

enum LoaderType {
  NONE,
  PDF,
  AUDIO_VIDEO,
}
const loaderType = computed(() => {
  switch (pickedUpload.value?.contentType) {
    case 'application/pdf':
      return LoaderType.PDF
    case undefined:
      return LoaderType.NONE
    default:
      return LoaderType.AUDIO_VIDEO
  }
})

const proposals = ref<StepProposal[]>([])
const onResult = (result: StepProposalResult) => {
  proposals.value = result.proposals
  errors.value = result.errors

  setTimeout(() => {
    stepperModel.value = STEP.CONVERT
  }, 2_000)
}

watch(asyncDialogIsOpen, (v) => {
  if (v) {
    stepperModel.value = STEP.FILE
    proposals.value = []
    errors.value = []
  }
})

const disclaimerOpen = ref(false)

defineExpose({ open: openAsyncDialog })
</script>

<template>
  <v-stepper v-if="asyncDialogResolve" v-model="stepperModel">
    <v-dialog v-model="asyncDialogIsOpen" width="1000" height="90%" scrollable persistent>
      <v-card>
        <v-card-title class="pa-0">
          <v-stepper-header>
            <v-stepper-item
              :value="STEP.FILE"
              :complete="isComplete(STEP.FILE)"
              :title="t('component.stepProposalsFromFileDialog.titlePickFile')"
            />
            <v-divider />
            <v-stepper-item
              :value="STEP.LOAD"
              :complete="isComplete(STEP.LOAD)"
              :title="t('component.stepProposalsFromFileDialog.titleLoad')"
            />
            <v-divider />
            <v-stepper-item
              :value="STEP.CONVERT"
              :complete="isComplete(STEP.CONVERT)"
              :title="t('component.stepProposalsFromFileDialog.titleConvertToSteps')"
            />
          </v-stepper-header>
        </v-card-title>
        <v-card-text class="py-0 align-content-center">
          <v-stepper-window>
            <v-stepper-window-item :value="STEP.FILE" class="windowCentered">
              <h2>{{ t('component.stepProposalsFromFileDialog.explanationTitle') }}</h2>
              <p>{{ t('component.stepProposalsFromFileDialog.explanationText') }}</p>
              <v-list density="compact" class="mb-3">
                <v-list-item>
                  <template #prepend>
                    <v-icon icon="mic" />
                  </template>
                  {{ t('component.stepProposalsFromFileDialog.explanationMedia') }}
                </v-list-item>
                <v-list-item>
                  <template #prepend>
                    <v-icon icon="description" />
                  </template>
                  {{ t('component.stepProposalsFromFileDialog.explanationPdf') }}
                </v-list-item>
              </v-list>

              <v-alert class="mb-7" type="warning" variant="tonal">
                <template #title>
                  {{ t('component.stepProposalsFromFileDialog.disclaimerTitle') }}
                  <v-icon
                    :icon="disclaimerOpen ? 'expand_less' : 'expand_more'"
                    @click="disclaimerOpen = !disclaimerOpen"
                  />
                </template>
                <template v-if="disclaimerOpen" #default>
                  {{ t('component.stepProposalsFromFileDialog.disclaimerText') }}
                </template>
              </v-alert>

              <text-field
                readonly
                :label="t('component.stepProposalsFromFileDialog.inputUpload')"
                :model-value="pickedUpload?.name"
                @click="uploadPickerOpen = true"
              >
                <template #append>
                  <v-btn variant="flat" icon="cloud_upload" />
                </template>
              </text-field>
            </v-stepper-window-item>
            <v-stepper-window-item :value="STEP.LOAD" class="windowCentered">
              <step-proposals-from-pdf-loader
                v-if="loaderType == LoaderType.PDF && pickedUpload"
                :upload="pickedUpload"
                @result="onResult"
              />
              <step-proposals-from-media-loader
                v-if="loaderType == LoaderType.AUDIO_VIDEO && pickedUpload"
                :upload="pickedUpload"
                @result="onResult"
              />
            </v-stepper-window-item>
            <v-stepper-window-item :value="STEP.CONVERT">
              <step-proposals-converter
                v-model:proposals="proposals"
                :product-id="props.productId"
                :module-id="props.moduleId"
                :site-id="props.siteId"
                @created="emit('created')"
                @done="asyncDialogResolve()"
              />
            </v-stepper-window-item>
          </v-stepper-window>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="stepperModel != STEP.CONVERT" @click="asyncDialogResolve()">
            {{ t('button.cancel') }}
          </v-btn>
          <v-btn
            v-if="stepperModel == STEP.CONVERT"
            color="primary"
            variant="elevated"
            @click="asyncDialogResolve()"
          >
            {{ t('button.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-stepper>

  <upload-picker
    v-model="uploadPickerOpen"
    pick-one
    :mime-types="mimeTypes"
    @pick-one="(v) => (pickedUpload = v)"
  />

  <async-dialog ref="errorDialog" width="500">
    <template #title>
      {{ t('component.stepProposalsFromFileDialog.errorTitle') }}
    </template>

    <template #default>
      {{ t('component.stepProposalsFromFileDialog.errorText') }}

      <ul class="mt-3">
        <li v-for="(e, i) in errors" :key="i">{{ e }}</li>
      </ul>
    </template>
    <template #actions="{ resolve }">
      <v-btn color="primary" variant="elevated" @click="resolve">
        {{ t('button.ok') }}
      </v-btn>
    </template>
  </async-dialog>
</template>

<style scoped lang="scss">
.windowCentered {
  width: 30em;
  margin-left: auto;
  margin-right: auto;

  button {
    width: 100%;
  }
}
</style>
