<script setup lang="ts" generic="T, MM">
import { Query } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed, watch } from 'vue'
import { itemSortByName } from '@/app'
import { useI18n } from 'vue-i18n'

const model = defineModel<string | undefined>()
const props = defineProps<{
  productId?: string
  label?: string
}>()

const { t } = useI18n()

const label = computed(() => props.label || t('entity.productConfiguration.singular'))

watch(
  () => props.productId,
  () => {
    model.value = undefined
  },
)
const disabled = computed(() => !props.productId)

const fetchQuery = useQuery<Query>(
  gql`
    query ProductConfigurationPickerData($productId: ID!) {
      product {
        product(id: $productId) {
          productConfigurations {
            id
            name
          }
        }
      }
    }
  `,
  () => ({
    productId: props.productId as string,
  }),
  () => ({
    enabled: !disabled.value,
  }),
)
const options = computed<SelectOption<string>[]>(() =>
  disabled.value
    ? []
    : itemSortByName(
        fetchQuery.result.value?.product.product.productConfigurations,
        (c) => c.name,
      ).map((c) => ({
        title: c.name,
        value: c.id,
      })),
)
</script>

<template>
  <select-field
    v-model="model"
    :label="label"
    :items="options"
    :loading="fetchQuery.loading.value"
    :disabled="disabled"
  />
</template>

<style scoped lang="scss"></style>
