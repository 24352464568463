import { customLightTheme } from '@/theme'

const startColor = customLightTheme.colors?.secondary as string
const endColor = customLightTheme.colors?.primary as string

const startColorRed = parseInt(startColor.slice(1, 3), 16)
const startColorGreen = parseInt(startColor.slice(3, 5), 16)
const startColorBlue = parseInt(startColor.slice(5, 7), 16)
const endColorRed = parseInt(endColor.slice(1, 3), 16)
const endColorGreen = parseInt(endColor.slice(3, 5), 16)
const endColorBlue = parseInt(endColor.slice(5, 7), 16)

export function useHexColorGradients(gradients: number) {
  return useRGBColorGradients(gradients).map((c) => `#${toHex(c[0])}${toHex(c[1])}${toHex(c[2])}`)
}

function toHex(val: number) {
  return val.toString(16).padStart(2, '0')
}

export function useRGBColorGradients(gradients: number) {
  const colors = [[startColorRed, startColorGreen, startColorBlue]]
  const midPoints = gradients - 2

  addMidPoints(colors, midPoints)

  colors.push([endColorRed, endColorGreen, endColorBlue])
  return colors
}

function addMidPoints(colors: number[][], midPoints: number) {
  if (midPoints <= 0) {
    return
  }

  const redIncrements = (endColorRed - startColorRed) / (midPoints + 1)
  const greenIncrements = (endColorGreen - startColorGreen) / (midPoints + 1)
  const blueIncrements = (endColorBlue - startColorBlue) / (midPoints + 1)

  for (let i = 0; i < midPoints; i++) {
    const red = Math.round(startColorRed + redIncrements * (i + 1))
    const green = Math.round(startColorGreen + greenIncrements * (i + 1))
    const blue = Math.round(startColorBlue + blueIncrements * (i + 1))
    colors.push([red, green, blue])
  }
}
