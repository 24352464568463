<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import { Query, Upload } from '@/generated/graphql'
import gql from 'graphql-tag'
import { computed, ref } from 'vue'
import FormField from '@/components/input/FormField.vue'
import UploadPicker, { PickedUpload } from '@/components/upload/UploadPicker.vue'

const props = defineProps<{
  modelValue: string[]
  label?: string
}>()
const emit = defineEmits<{
  'update:modelValue': [value: string[]]
}>()

const imageMaxDimension = 75
const ids = computed(() => props.modelValue)
const fetchQuery = useQuery<Query, { ids: string[]; maxDimension: number }>(
  gql`
    query getUploadUrlForImages($ids: [ID!]!, $maxDimension: Int!) {
      upload {
        uploads(ids: $ids) {
          id
          imageUrl(options: { width: $maxDimension, height: $maxDimension })
        }
      }
    }
  `,
  () => ({
    ids: ids.value,
    maxDimension: imageMaxDimension,
  }),
  () => ({
    enabled: ids.value.length > 0,
  }),
)
const images = computed<Upload[]>(() => {
  const uploads = fetchQuery.result.value?.upload.uploads
  if (!uploads) {
    return []
  }

  return props.modelValue
    .map((v) => uploads.find((u) => u.id == v))
    .filter((u) => !!u)
    .map((u) => u as Upload)
})
function removeUpload(e: DragEvent) {
  const draggedNodeId = e.dataTransfer?.getData('nodeId') as string
  emit(
    'update:modelValue',
    ids.value.filter((i) => i != draggedNodeId),
  )
}
function addUploads(uploads: PickedUpload[]) {
  emit('update:modelValue', [...ids.value, ...uploads.map((u) => u.id)])
}

const uploadPickerOpen = ref(false)

const classDropContainer = 'drop-container'
const classDropHover = 'drop-hover'
const classDragging = 'dragging'
function resetDragHover() {
  document.querySelectorAll(`.${classDropHover}`).forEach((e) => e.classList.remove(classDropHover))
}
function dragStart(e: DragEvent, nodeId: string) {
  e.dataTransfer!.effectAllowed = 'move'
  e.dataTransfer!.dropEffect = 'move'
  e.dataTransfer!.setData('nodeId', nodeId)
  document.querySelectorAll(`.${classDropContainer}`).forEach((e) => e.classList.add(classDragging))
}
function dragEnd() {
  document
    .querySelectorAll(`.${classDropContainer}`)
    .forEach((e) => e.classList.remove(classDragging))
}

function dragEnter(e: DragEvent) {
  const target = e.target as HTMLElement
  if (!target.classList.contains(classDropContainer)) {
    return
  }

  target.classList.add(classDropHover)
}

function dragLeave(e: DragEvent) {
  const target = e.target as HTMLElement
  if (!target.classList.contains(classDropContainer)) {
    return
  }

  target.classList.remove(classDropHover)
}

function dropBefore(e: DragEvent, beforeNodeId: string) {
  resetDragHover()
  const draggedNodeId = e.dataTransfer?.getData('nodeId') as string
  const currentIndex = props.modelValue.findIndex((n) => n == draggedNodeId)
  const targetIndex = props.modelValue.findIndex((n) => n == beforeNodeId)
  if (currentIndex == targetIndex) {
    return
  }

  const newNodes = [...props.modelValue]
  newNodes.splice(targetIndex, 0, newNodes.splice(currentIndex, 1)[0])

  emit('update:modelValue', newNodes)
}
</script>

<template>
  <div style="position: relative">
    <form-field :label="props.label">
      <v-btn icon="add" class="add-button mr-3" @click="uploadPickerOpen = true" />
      <div
        :class="`${classDropContainer} drop-delete`"
        @dragenter.prevent="dragEnter"
        @dragover.prevent
        @dragleave.prevent="dragLeave"
        @drop="(e) => removeUpload(e)"
      >
        <v-icon icon="delete" color="error" size="30" />
      </div>
      <div v-for="img in images" :key="img.id" class="img-container">
        <div
          :class="`${classDropContainer} drop-left`"
          @dragenter.prevent="dragEnter"
          @dragover.prevent
          @dragleave.prevent="dragLeave"
          @drop="(e) => dropBefore(e, img.id)"
        />
        <div
          :class="`${classDropContainer} drop-right`"
          @dragenter.prevent="dragEnter"
          @dragover.prevent
          @dragleave.prevent="dragLeave"
          @drop="(e) => dropBefore(e, img.id)"
        />
        <div draggable="true" @dragstart="(e) => dragStart(e, img.id)" @dragend="dragEnd()">
          <v-icon icon="drag_indicator" class="drag-handle" />
          <v-img
            :src="img.imageUrl"
            :height="`${imageMaxDimension}px`"
            :width="`${imageMaxDimension}px`"
            class="position-relative"
          />
        </div>
      </div>
    </form-field>
  </div>
  <upload-picker
    v-model="uploadPickerOpen"
    :mime-types="['image/png', 'image/jpeg', 'image/webp', 'image/svg+xml']"
    @pick-multiple="addUploads"
  />
</template>

<style scoped lang="scss">
.add-button {
  vertical-align: top;
  margin-top: 0.8em;
}

.drop-container {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: 99;

  * {
    pointer-events: none;
  }
}
.drop-container.drop-left {
  top: 0;
  left: -25%;
}
.drop-container.drop-right {
  top: 0;
  right: -25%;
}
.drop-container.drop-hover {
  border: #555 dashed 2px;
}
.drop-container.drop-delete {
  text-align: center;
  top: -2em;
  display: none;
  left: calc(50% - 2em);
  width: 4em;
  height: 4em;
  padding-top: 1em;
}
.drop-container.drop-delete.dragging {
  display: inline-block;
}

.img-container {
  display: inline-block;
  position: relative;
  width: max-content;
  height: max-content;

  .drag-handle {
    z-index: 110;
    cursor: grab;
    position: absolute;
    top: 1em;
    left: 1em;
  }
}
</style>
