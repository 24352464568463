<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { ref, watch } from 'vue'
import BopPicker from '@/components/input/BopPicker.vue'
import ModulePicker from '@/components/input/ModulePicker.vue'
import SitePicker from '@/components/input/SitePicker.vue'
import StepPicker from '@/components/input/StepPicker.vue'
import ProductConfigurationPicker from '@/components/input/ProductConfigurationPicker.vue'
import useAsyncDialog from '@/components/dialogs/useAsyncDialog'
import { DuplicateStepMutation, DuplicateStepMutationVariables } from '@/generated/graphql'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'

const props = defineProps<{
  productId: string
  targetBopId: string
  targetModuleId: string
  initialConfigId?: string
  initialSiteId?: string
  initialStepId?: string
}>()
const emit = defineEmits<{
  duplicated: [id: string]
}>()

const { t } = useI18n()
const { openAsyncDialog, asyncDialogResolve, asyncDialogLoading, asyncDialogIsOpen } =
  useAsyncDialog<() => Promise<void>>()

const configId = ref<string | undefined>(props.initialConfigId)
const siteId = ref<string | undefined>(props.initialConfigId)
const bopId = ref<string | undefined>(props.targetBopId)
const moduleId = ref<string | undefined>(props.targetModuleId)
const stepId = ref<string | undefined>(props.initialStepId)
watch(asyncDialogIsOpen, (v) => {
  if (v) {
    configId.value = props.initialConfigId
    siteId.value = props.initialSiteId
    bopId.value = props.targetBopId
    moduleId.value = props.targetModuleId
    stepId.value = props.initialStepId
  }
})

const copyMutation = useMutation<DuplicateStepMutation, DuplicateStepMutationVariables>(gql`
  mutation DuplicateStep($command: DuplicateStep!) {
    product {
      duplicateStep(command: $command) {
        id
      }
    }
  }
`)

const notifySaved = ref(false)
const save = async () => {
  if (!stepId.value || !asyncDialogResolve.value) {
    return
  }

  asyncDialogResolve.value(async () => {
    const response = await copyMutation.mutate({
      command: {
        fromBOPId: bopId.value as string,
        fromModuleId: moduleId.value as string,
        fromStepId: stepId.value as string,
        toBOPId: props.targetBopId,
        toModuleId: props.targetModuleId,
      },
    })
    emit('duplicated', response?.data?.product?.duplicateStep?.id as string)
    notifySaved.value = true
  })
}

defineExpose({ open: openAsyncDialog })
defineOptions({ inheritAttrs: false })
</script>

<template>
  <template v-if="asyncDialogResolve">
    <v-dialog v-model="asyncDialogIsOpen" scrollable width="500" v-bind="$attrs">
      <v-card>
        <v-card-title>{{ t('component.duplicateStep.title') }}</v-card-title>
        <v-card-text>
          <product-configuration-picker v-model="configId" :product-id="props.productId" required />
          <site-picker v-model="siteId" required />
          <bop-picker
            v-model="bopId"
            :product-configuration-id="configId"
            :site-id="siteId"
            required
          />
          <module-picker v-model="moduleId" :bop-id="bopId" required />
          <step-picker v-model="stepId" :module-id="moduleId" required />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="asyncDialogResolve()">{{ t('button.cancel') }}</v-btn>
          <v-btn :disabled="!stepId" :loading="asyncDialogLoading" @click="save">
            {{ t('button.copy') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <notification-saved v-model="notifySaved" />
</template>

<style scoped lang="scss"></style>
