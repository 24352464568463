<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  PdfExtension,
  StepProposalResult,
  StepProposalsFromPdfAnalysisQuery,
  StepProposalsFromPdfAnalysisQueryVariables,
  StepProposalsFromPdfMutation,
  StepProposalsFromPdfMutationVariables,
} from '@/generated/graphql'
import { useMutation, useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, reactive, ref, watch } from 'vue'
import { v4 } from 'uuid'
import { PickedUpload } from '@/components/upload/UploadPicker.vue'
import StepProposalsProgress from '@/components/step/StepProposalsProgress.vue'
import IntegerField from '@/components/input/IntegerField.vue'
import useVuelidate from '@vuelidate/core'
import { maxDifference, maxValue, minValue, required } from '@/validation'

const props = defineProps<{
  upload: PickedUpload
}>()
const emit = defineEmits<{
  result: [value: StepProposalResult]
}>()
const { t } = useI18n()

const pdfAnalysisQuery = useQuery<
  StepProposalsFromPdfAnalysisQuery,
  StepProposalsFromPdfAnalysisQueryVariables
>(
  gql`
    query StepProposalsFromPdfAnalysis($id: ID!) {
      upload {
        upload(id: $id) {
          extension {
            ... on PdfExtension {
              analysis {
                numberOfPages
                numberOfImages
              }
            }
          }
        }
      }
    }
  `,
  () => ({
    id: props.upload.id,
  }),
)
const extension = computed(() => pdfAnalysisQuery.result.value?.upload.upload.extension)
const analysis = computed(() =>
  extension.value ? (extension.value as PdfExtension).analysis : undefined,
)

type Model = {
  first: number
  last: number
}
const model = reactive<Model>({
  first: 1,
  last: 1,
})
watch(analysis, (v) => {
  model.last = v?.numberOfPages || 1
})

const validationRules = computed(() => {
  return {
    first: { required, min: minValue(1), max: maxValue(analysis.value?.numberOfImages || 1) },
    last: {
      required,
      min: minValue(model.first),
      max: maxValue(analysis.value?.numberOfPages || 1),
      maxDiff: maxDifference(50, model.first),
    },
  }
})
const validation = useVuelidate<Model>(validationRules, model)

const mutation = useMutation<StepProposalsFromPdfMutation, StepProposalsFromPdfMutationVariables>(
  gql`
    mutation StepProposalsFromPdf($command: CreateStepProposalsFromPDF!) {
      product {
        createStepProposalsFromPDF(command: $command) {
          errors
          proposals {
            id
            name
            instructionText
            durationInSeconds
          }
        }
      }
    }
  `,
)

const loadingRequestId = ref<string>()
const doProposals = () => {
  validation.value.$touch()
  if (validation.value.$invalid) {
    return
  }

  loadingRequestId.value = v4()
  mutation
    .mutate({
      command: {
        id: loadingRequestId.value as string,
        fromUploadId: props.upload.id,
        firstPage: model.first - 1,
        lastPage: model.last - 1,
      },
    })
    .then((response) => {
      emit('result', response?.data?.product.createStepProposalsFromPDF as StepProposalResult)
    })
    .finally(() => {
      setTimeout(() => (loadingRequestId.value = undefined), 3_000)
    })
}

const expectedExtractionTime = computed(() => (model.last - model.first) * 5)
</script>

<template>
  <div v-if="!loadingRequestId">
    <integer-field
      v-model="model.first"
      :label="t('component.stepProposalsFromPdfLoader.inputFirstPage')"
      :validation="validation.first"
      required
    />
    <integer-field
      v-model="model.last"
      :label="t('component.stepProposalsFromPdfLoader.inputLastPage')"
      :validation="validation.last"
      required
    >
      <template #append> &le; {{ analysis?.numberOfPages }} </template>
    </integer-field>
    <div class="text-center">
      <v-btn color="primary" class="mt-5" :loading="mutation.loading.value" @click="doProposals">
        {{ t('component.stepProposalsFromFileDialog.buttonCreateProposals') }}
      </v-btn>
    </div>
  </div>
  <step-proposals-progress
    v-if="!!loadingRequestId"
    :id="loadingRequestId"
    :expected-seconds-extraction="expectedExtractionTime"
  />
</template>

<style scoped lang="scss"></style>
