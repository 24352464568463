<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { onUnmounted, ref, watch } from 'vue'
import { secondsToHumanReadable } from '@/app'

const props = defineProps<{
  id?: string
  expectedSecondsExtraction?: number
}>()

const { t } = useI18n()

enum LoaderStep {
  OPTIMIZING_FORMAT = 0,
  EXTRACTING_STEPS = 1,
  CLUSTERING = 2,
  DONE = 3,
}
const loaderModel = ref<LoaderStep>(LoaderStep.OPTIMIZING_FORMAT)
const bufferModel = ref<number>(LoaderStep.OPTIMIZING_FORMAT)
watch(loaderModel, (v) => {
  if (bufferModel.value < v) {
    bufferModel.value = v
  }
})

const extractionTimer = ref<string>()
let countDown: number
watch(loaderModel, (v) => {
  if (v != LoaderStep.EXTRACTING_STEPS || !props.expectedSecondsExtraction) {
    return
  }

  clearInterval(countDown)
  const expected = props.expectedSecondsExtraction
  let timer = props.expectedSecondsExtraction
  const bufferInterval = 1 / props.expectedSecondsExtraction
  countDown = setInterval(() => {
    timer--
    extractionTimer.value = secondsToHumanReadable(timer)
    bufferModel.value = LoaderStep.EXTRACTING_STEPS + (expected - timer) * bufferInterval

    if (timer < 0) {
      clearInterval(countDown)
    }
  }, 1_000)
})

const eventSource = ref<EventSource>()
onUnmounted(() => {
  eventSource.value?.close()
  eventSource.value = undefined
})

const baseUrl = import.meta.env.VITE_REST_API_BASE_URL as string
watch(
  () => props.id,
  () => {
    const id = props.id
    if (!id) {
      return
    }

    loaderModel.value = LoaderStep.OPTIMIZING_FORMAT
    const source = new EventSource(`${baseUrl}/sse/step-proposals-from-media/${id}`)
    source.addEventListener('OPTIMIZING_FORMAT', () => {
      loaderModel.value = LoaderStep.OPTIMIZING_FORMAT
    })
    source.addEventListener('EXTRACTING_STEPS', () => {
      loaderModel.value = LoaderStep.EXTRACTING_STEPS
    })
    source.addEventListener('CLUSTERING', () => {
      loaderModel.value = LoaderStep.CLUSTERING
    })
    source.addEventListener('DONE', () => {
      loaderModel.value = LoaderStep.DONE
      source.close()
      eventSource.value = undefined
    })
    eventSource.value = source
  },
  { immediate: true },
)
</script>

<template>
  <v-window v-model="loaderModel">
    <v-window-item :value="LoaderStep.OPTIMIZING_FORMAT" class="loaderWindow">
      <v-progress-circular indeterminate class="mb-5" size="100" color="secondary" />
      <h1>{{ t('component.stepProposalsProgress.optimizing.title') }}</h1>
      <p>{{ t('component.stepProposalsProgress.optimizing.message') }}</p>
    </v-window-item>
    <v-window-item :value="LoaderStep.EXTRACTING_STEPS" class="loaderWindow">
      <v-progress-circular indeterminate class="mb-5" size="100" color="secondary">
        {{ extractionTimer ? `~${extractionTimer}` : '' }}
      </v-progress-circular>

      <h1>{{ t('component.stepProposalsProgress.extracting.title') }}</h1>
      <p>{{ t('component.stepProposalsProgress.extracting.message') }}</p>
    </v-window-item>
    <v-window-item :value="LoaderStep.CLUSTERING" class="loaderWindow">
      <v-progress-circular indeterminate class="mb-5" size="100" color="secondary" />
      <h1>{{ t('component.stepProposalsProgress.clustering.title') }}</h1>
      <p>{{ t('component.stepProposalsProgress.clustering.message') }}</p>
    </v-window-item>
    <v-window-item :value="LoaderStep.DONE" class="loaderWindow">
      <v-icon icon="done" color="green" size="80" />
      <h1>{{ t('component.stepProposalsProgress.done.title') }}</h1>
      <p>{{ t('component.stepProposalsProgress.done.message') }}</p>
    </v-window-item>
  </v-window>
  <v-progress-linear
    :max="LoaderStep.DONE"
    :model-value="loaderModel"
    :buffer-value="bufferModel"
    stream
    height="8"
    color="secondary"
  />
</template>

<style scoped lang="scss">
.loaderWindow {
  padding-bottom: 5em;
  text-align: center;
}
</style>
