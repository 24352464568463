<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { StepProposal } from '@/generated/graphql'
import CreateStep from '@/components/step/CreateStep.vue'
import { watch } from 'vue'

const proposals = defineModel<StepProposal[]>('proposals', { required: true })
const emit = defineEmits<{
  created: []
  done: []
}>()

const { t } = useI18n()

const removeProposal = (id: string) => {
  proposals.value = proposals.value.filter((p) => p.id != id)
}
const onCreated = (proposalId: string) => {
  removeProposal(proposalId)
  emit('created')
}
watch(proposals, (v) => {
  if (v.length == 0) {
    emit('done')
  }
})

defineOptions({ inheritAttrs: false })
</script>

<template>
  <v-alert
    v-if="proposals.length == 0"
    type="info"
    class="mx-auto"
    :title="t('component.stepProposalsConverter.titleNoSteps')"
    width="400"
  >
    {{ t('component.stepProposalsConverter.textNoSteps') }}
  </v-alert>

  <v-expansion-panels variant="accordion" multiple>
    <v-expansion-panel v-for="(p, i) in proposals" :key="p.id">
      <v-expansion-panel-title>
        <template #default="{ expanded }">
          <component :is="expanded ? 'h2' : 'span'">{{ p.name }}</component>
        </template>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <create-step
          v-bind="$attrs"
          :init-name="p.name"
          :init-instruction-text="p.instructionText"
          :init-duration-in-seconds="p.durationInSeconds"
          without-title
          @created="onCreated(p.id)"
        />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style scoped lang="scss"></style>
