<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { computed, reactive, ref, watch } from 'vue'
import useAsyncDialog from '@/components/dialogs/useAsyncDialog'
import {
  IssueDialogDataQuery,
  IssueDialogDataQueryVariables,
  IssueDialogDeleteMutation,
  IssueDialogDeleteMutationVariables,
  IssueDialogUpdateMutation,
  IssueDialogUpdateMutationVariables,
  UpdateIssue,
} from '@/generated/graphql'
import DateTime from '@/components/DateTime.vue'
import IssueCommentList from '@/components/issue/IssueCommentList.vue'
import UploadList from '@/components/upload/UploadList.vue'
import TextField from '@/components/input/TextField.vue'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import { mockUpdateIssue } from '@/generated/graphql-mocks'
import { ComponentExposed } from 'vue-component-type-helpers'
import DeleteDialogNext from '@/components/dialogs/AsyncDeleteDialog.vue'
import AsyncDeleteDialog from '@/components/dialogs/AsyncDeleteDialog.vue'
import useVuelidate from '@vuelidate/core'
import { normalName, required } from '@/validation'
import { currentUserAndPermissions } from '@/app'
import TextAreaField from '@/components/input/TextAreaField.vue'
import OrganizationUserPicker from '@/components/input/OrganizationUserPicker.vue'
import MultiUploadPicker from '@/components/input/MultiUploadPicker.vue'
import Checkbox from '@/components/input/Checkbox.vue'
import OrganizationUserList from '@/components/user/OrganizationUserList.vue'
import IssueLinkEditList from '@/components/issue/IssueLinkEditList.vue'
import ButtonDelete from '@/components/button/ButtonDelete.vue'
import ButtonEdit from '@/components/button/ButtonEdit.vue'
import Markdown from '@/components/Markdown.vue'
import { issueRefetchQueries } from '@/components/issue/issueRefetchQueries'

const { t } = useI18n()
const { openAsyncDialog, asyncDialogResolve, asyncDialogLoading, asyncDialogIsOpen } =
  useAsyncDialog<() => Promise<void>>()

type LocalIssue = IssueDialogDataQuery['issue']['issue']
const id = ref<string>()
watch(asyncDialogIsOpen, (v) => {
  if (!v) {
    id.value = undefined
  }
})

const fetchQuery = useQuery<IssueDialogDataQuery, IssueDialogDataQueryVariables>(
  gql`
    query IssueDialogData($id: ID!) {
      issue {
        issue(id: $id) {
          id
          createdAt
          createdByUser {
            id
            firstname
            lastname
          }
          description
          name
          resolvedAt
          updatedAt
          uploadIds
          assignedToUserIds
        }
      }
    }
  `,
  () => ({
    id: id.value as string,
  }),
  () => ({
    enabled: !!id.value,
  }),
)
const issue = computed(() => fetchQuery.result.value?.issue.issue)

const open = async (initId: string) => {
  id.value = initId
  return openAsyncDialog()
}
defineExpose({ open })

const currentUserId = computed(() => currentUserAndPermissions.value?.user.id)

type UpdateModel = Partial<UpdateIssue>
const updateModel = reactive<UpdateModel>({})
const updateValidation = useVuelidate<UpdateModel>(
  {
    name: { required, normalName },
    description: { required },
  },
  updateModel,
  { $scope: false },
)
const updateModelKeys = Object.keys(mockUpdateIssue())
const updateMutation = useMutation<IssueDialogUpdateMutation, IssueDialogUpdateMutationVariables>(
  gql`
    mutation IssueDialogUpdate($command: UpdateIssue!) {
      issue {
        updateIssue(command: $command) {
          id
        }
      }
    }
  `,
  { refetchQueries: issueRefetchQueries },
)
const updateDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const doUpdate = async (item: LocalIssue) => {
  updateModelKeys.forEach((k) => {
    ;(updateModel as Record<string, unknown>)[k] = item[k as keyof LocalIssue]
  })
  updateModel.resolved = !!item.resolvedAt
  await updateDialog.value?.open(() =>
    updateMutation.mutate({ command: updateModel as UpdateIssue }).then(() => fetchQuery.refetch()),
  )
}

const deleteMutation = useMutation<IssueDialogDeleteMutation, IssueDialogDeleteMutationVariables>(
  gql`
    mutation IssueDialogDelete($id: ID!) {
      issue {
        deleteIssue(id: $id)
      }
    }
  `,
  { refetchQueries: issueRefetchQueries },
)
const deleteDialog = ref<ComponentExposed<typeof DeleteDialogNext>>()
const doDelete = async (item: LocalIssue) => {
  await deleteDialog.value?.open(item.name, () =>
    deleteMutation.mutate({ id: item.id }).then(() => {
      if (asyncDialogResolve.value) {
        asyncDialogResolve.value()
      }
    }),
  )
}
</script>

<template>
  <template v-if="asyncDialogResolve">
    <v-dialog v-model="asyncDialogIsOpen" width="1200" v-bind="$attrs" scrollable>
      <v-card :loading="fetchQuery.loading.value">
        <v-card-title>
          {{ issue?.name }}

          <v-btn
            icon="close"
            variant="flat"
            density="compact"
            class="float-right"
            @click="asyncDialogResolve()"
          />
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <h3>{{ t('entity.issue.field.description') }}</h3>
              <markdown :text="issue?.description" />

              <h3>{{ t('entity.issue.field.uploads') }}</h3>
              <upload-list v-if="issue" :ids="issue.uploadIds" />

              <h3>{{ t('entity.issue.field.assignedToUsers') }}</h3>
              <organization-user-list v-if="issue" :ids="issue.assignedToUserIds" />

              <div class="text-right mt-3 mb-2">
                <button-edit v-if="issue" @click="doUpdate(issue)" />
                <button-delete
                  v-if="issue && currentUserId == issue.createdByUser.id"
                  @click="doDelete(issue)"
                />
              </div>
              <v-divider thickness="1" class="mb-10" />

              <issue-link-edit-list
                v-if="!!id"
                :issue-id="id"
                create-button-container="#issueLinksHeader"
              >
                <template #header="props">
                  <h3>
                    {{ t('entity.issueLink.plural') }}
                    <v-btn
                      icon="add"
                      variant="tonal"
                      density="compact"
                      color="primary"
                      class="float-right"
                      v-bind="props"
                    />
                  </h3>
                </template>
              </issue-link-edit-list>

              <issue-comment-list
                v-if="!!id"
                :issue-id="id"
                create-button-container="#issueCommentsHeader"
              >
                <template #header="props">
                  <h3>
                    {{ t('entity.issueComment.plural') }}
                    <v-btn
                      icon="add"
                      variant="tonal"
                      color="primary"
                      density="compact"
                      class="float-right"
                      v-bind="props"
                    />
                  </h3>
                </template>
              </issue-comment-list>
            </v-col>
            <v-col>
              <dl>
                <dd>{{ t('entity.issue.field.createdByUser') }}</dd>
                <dt>{{ issue?.createdByUser.firstname }} {{ issue?.createdByUser.lastname }}</dt>
                <dd>{{ t('entity.issue.field.createdAt') }}</dd>
                <dt><date-time :model-value="issue?.createdAt" /></dt>
                <dd>{{ t('entity.issue.field.updatedAt') }}</dd>
                <dt><date-time :model-value="issue?.updatedAt" /></dt>
                <dd>{{ t('entity.issue.field.resolvedAt') }}</dd>
                <dt><date-time :model-value="issue?.resolvedAt" /></dt>
              </dl>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn variant="elevated" color="primary" @click="asyncDialogResolve()">
            {{ t(`button.close`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <async-delete-dialog ref="deleteDialog" />

  <upsert-dialog ref="updateDialog" type="update" :validation="updateValidation">
    <text-field
      v-model="updateModel.name"
      :label="t('entity.issue.field.name')"
      :validation="updateValidation.name"
      required
    />
    <text-area-field
      v-model="updateModel.description"
      :label="t('entity.issue.field.description')"
      :validation="updateValidation.description"
      required
      markdown
    />
    <checkbox v-model="updateModel.resolved" :label="t('entity.issue.field.resolved')" />
    <organization-user-picker
      v-model="updateModel.assignedToUserIds"
      :label="t('entity.issue.field.assignedToUsers')"
    />
    <multi-upload-picker
      v-model="updateModel.uploadIds"
      :mime-types="[]"
      :label="t('entity.issue.field.uploads')"
    />
  </upsert-dialog>
</template>

<style scoped lang="scss">
h3 {
  margin-top: 1.3em;
  margin-bottom: 0.2em;

  &:first-of-type {
    margin-top: 0;
  }
}
dd {
  font-size: 0.8em;
  color: #888;
}
dt {
  margin-bottom: 0.5em;
}
</style>
