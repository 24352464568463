<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { IssuesPerDayGraphQuery, IssuesPerDayGraphQueryVariables } from '@/generated/graphql'
import { computed, onUnmounted, ref, watch } from 'vue'
import { DateTime } from 'luxon'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js'
import { useRGBColorGradients } from '@/useColorGradients'
import 'chartjs-adapter-luxon'
import useCharts from '@/composables/useCharts'

const { t } = useI18n()

const initModel = () => {
  let day = DateTime.now().minus({ week: 2 })
  const now = DateTime.now()
  const range: Date[] = []
  while (day < now) {
    range.push(day.toJSDate())
    day = day.plus({ day: 1 })
  }

  return range
}
const model = ref<Date[]>(initModel())

const modelSorted = computed(() => model.value.toSorted((a, b) => a.getTime() - b.getTime()))
const dateFormat = "yyyy-MM-dd'T'HH:mm:ss'+00:00'"
const from = computed(() =>
  DateTime.fromJSDate(modelSorted.value[0]).startOf('day').toFormat(dateFormat),
)
const till = computed(() =>
  DateTime.fromJSDate(modelSorted.value[modelSorted.value.length - 1])
    .endOf('day')
    .toFormat(dateFormat),
)
const fetchQuery = useQuery<IssuesPerDayGraphQuery, IssuesPerDayGraphQueryVariables>(
  gql`
    query IssuesPerDayGraph($from: DateTime!, $till: DateTime!) {
      issue {
        issuesPerDay(from: $from, till: $till) {
          day
          created
          resolved
          open
        }
      }
    }
  `,
  () => ({
    from: from.value,
    till: till.value,
  }),
  () => ({
    enabled: model.value.length > 1,
  }),
)
const issuesPerDay = computed(() => fetchQuery.result.value?.issue.issuesPerDay || [])

const [colorCreated, colorResolved, colorTotal] = useRGBColorGradients(3)
const canvas = ref<HTMLCanvasElement>()
const chart = ref<Chart<'line' | 'bar'>>()
const resetChart = () => {
  if (chart.value) {
    chart.value.destroy()
    chart.value = undefined
  }
}
onUnmounted(() => resetChart())

useCharts(
  BarController,
  BarElement,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Title,
  Legend,
  Tooltip,
  Filler,
  CategoryScale,
)
watch([canvas, issuesPerDay], () => {
  if (!canvas.value || issuesPerDay.value.length == 0) {
    return
  }

  resetChart()

  const dateFormat = 'd-M'

  chart.value = new Chart(canvas.value, {
    options: {
      responsive: true,
      plugins: {
        datalabels: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              return DateTime.fromMillis(context[0].parsed.x).toFormat(dateFormat)
            },
          },
        },
      },
      scales: {
        x: {
          type: 'time',
          time: {
            round: 'day',
            unit: 'day',
            displayFormats: {
              day: dateFormat,
            },
          },
          grid: {
            drawOnChartArea: false,
          },
        },
        y: {
          type: 'linear',
          suggestedMin: 0,
          ticks: {
            precision: 0,
          },
          grid: {
            drawOnChartArea: false,
          },
        },
        y1: {
          type: 'linear',
          position: 'right',
          ticks: {
            precision: 0,
          },
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    },
    data: {
      labels: issuesPerDay.value.map((i) => i.day),
      datasets: [
        {
          type: 'line',
          label: 'Open',
          data: issuesPerDay.value.map((i) => i.open),
          borderColor: `rgba(${colorTotal[0]}, ${colorTotal[1]}, ${colorTotal[2]}, 1.0)`,
          backgroundColor: `rgba(${colorTotal[0]}, ${colorTotal[1]}, ${colorTotal[2]}, 1.0)`,
          cubicInterpolationMode: 'monotone',
          yAxisID: 'y1',
          pointRadius: 1,
        },
        {
          type: 'bar',
          label: 'Created',
          data: issuesPerDay.value.map((i) => i.created),
          borderColor: `rgba(${colorCreated[0]}, ${colorCreated[1]}, ${colorCreated[2]}, 0.8)`,
          backgroundColor: `rgba(${colorCreated[0]}, ${colorCreated[1]}, ${colorCreated[2]}, 0.3)`,
          yAxisID: 'y',
        },
        {
          type: 'bar',
          label: 'Resolved',
          data: issuesPerDay.value.map((i) => i.resolved),
          borderColor: `rgba(${colorResolved[0]}, ${colorResolved[1]}, ${colorResolved[2]}, 0.8)`,
          backgroundColor: `rgba(${colorResolved[0]}, ${colorResolved[1]}, ${colorResolved[2]}, 0.3)`,
          yAxisID: 'y',
        },
      ],
    },
  })
})

const today = DateTime.now().endOf('day')
const isDateAllowed = (date: Date) => {
  return DateTime.fromJSDate(date) < today
}
</script>

<template>
  <div class="float-left">
    <v-date-picker
      v-model="model"
      multiple="range"
      first-day-of-week="1"
      show-adjacent-months
      hide-header
      color="secondary"
      class="mx-auto"
      :allowed-dates="isDateAllowed"
    />
  </div>
  <div class="float-left canvasContainer">
    <canvas ref="canvas" v-bind="$attrs" />
  </div>
</template>

<style scoped lang="scss">
.canvasContainer {
  width: 100%;
  max-width: 70em;
  height: 30em;
}
</style>
