<script setup lang="ts" generic="I">
import { useI18n } from 'vue-i18n'
import useAsyncDialog from '@/components/dialogs/useAsyncDialog'

const { t } = useI18n()
const { openAsyncDialog, asyncDialogResolve, asyncDialogLoading, asyncDialogIsOpen } =
  useAsyncDialog<() => Promise<void>>()

const open = async () => {
  return openAsyncDialog()
}

defineExpose({ open })
</script>

<template>
  <v-dialog v-if="asyncDialogResolve" v-model="asyncDialogIsOpen" v-bind="$attrs" scrollable>
    <v-card>
      <v-card-title>
        <slot name="title" />

        <v-btn
          density="compact"
          icon="close"
          class="float-right"
          variant="flat"
          @click="asyncDialogResolve()"
        />
      </v-card-title>
      <v-card-text>
        <slot name="default" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <slot name="actions" :resolve="asyncDialogResolve" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
