<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onUnmounted, reactive, ref, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import {
  UploadListItem,
  UploadPickerDataQuery,
  UploadPickerDataQueryVariables,
  UploadPickerSelectedUploadsQuery,
  UploadPickerSelectedUploadsQueryVariables,
  UploadQuery,
  UploadSortDirection,
  UploadSortField,
} from '@/generated/graphql'
import TextField from '@/components/input/TextField.vue'
import DateTime from '@/components/DateTime.vue'
import UploadButton from '@/components/upload/UploadButton.vue'
import useUploads from '@/composables/useUploads'

export type PickedUpload = {
  id: string
  contentType: string
  name: string
}
type UploadTableItem = PickedUpload & {
  isSelectable: boolean
}

const props = defineProps<{
  modelValue: boolean
  mimeTypes: string[]
  pickOne?: boolean
}>()
const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  pickOne: [value: PickedUpload]
  pickMultiple: [value: PickedUpload[]]
}>()

const { t } = useI18n()

const searchQuery = reactive<UploadQuery>({
  page: 0,
  sortBy: UploadSortField.CreatedAt,
  sortDirection: UploadSortDirection.Desc,
})
watch(
  () => props.mimeTypes,
  (v) => {
    searchQuery.inContentTypes = v.length == 0 ? undefined : v
  },
  { immediate: true },
)

const selectedUploadIds = ref<string[]>([])
watch(
  () => props.modelValue,
  (v) => {
    if (v) {
      selectedUploadIds.value = []
    }
  },
)
const onRowClick = (_: unknown, row: { item: UploadTableItem }) => {
  const item = row.item
  if (!item.isSelectable) {
    return
  }

  if (props.pickOne) {
    selectedUploadIds.value = [item.id]
  } else {
    selectedUploadIds.value.push(item.id)
  }
}

const fetchQuery = useQuery<UploadPickerDataQuery, UploadPickerDataQueryVariables>(
  gql`
    query UploadPickerData($query: UploadQuery!) {
      upload {
        search(query: $query) {
          id
          contentType
          name
          createdAt
        }
      }
    }
  `,
  () => ({
    query: searchQuery,
  }),
  {
    debounce: 500,
  },
)
const fetchedUploads = computed<UploadListItem[]>(
  () => fetchQuery.result.value?.upload.search || [],
)

const selectedUploadsQuery = useQuery<
  UploadPickerSelectedUploadsQuery,
  UploadPickerSelectedUploadsQueryVariables
>(
  gql`
    query UploadPickerSelectedUploads($ids: [ID!]!) {
      upload {
        uploads(ids: $ids) {
          id
          contentType
          name
        }
      }
    }
  `,
  () => ({
    ids: selectedUploadIds.value,
  }),
)
function selectUploads() {
  const selectedUploads = selectedUploadsQuery.result.value?.upload.uploads || []

  if (props.pickOne) {
    emit('pickOne', selectedUploads[0])
  } else {
    emit('pickMultiple', selectedUploads)
  }

  emit('update:modelValue', false)
}

const headers = [
  { value: 'name', title: t('entity.upload.field.name'), sortable: false },
  { value: 'contentType', title: t('entity.upload.field.contentType'), sortable: false },
  { value: 'createdAt', title: t('entity.upload.field.createdAt'), sortable: false },
]

const { uploadsInProgress, addUploadDoneListener } = useUploads()
onUnmounted(
  addUploadDoneListener((upload) => {
    fetchQuery.refetch()

    if (props.pickOne) {
      selectedUploadIds.value = [upload.id]
      return
    }

    selectedUploadIds.value.push(upload.id)
  }),
)
const justUploadedIds = ref<string[]>([])
const items = computed(() => {
  const inProgress = uploadsInProgress.value
    .filter((u) => justUploadedIds.value.includes(u.id))
    .map((u) => ({
      name: u.fileName,
      contentType: u.file.type,
      isSelectable: false,
      __typename: '_UploadInProgress',
    }))
  const uploads = fetchedUploads.value.map((u) => ({ ...u, isSelectable: true }))
  return [...inProgress, ...uploads]
})

const canSelectUploads = computed(
  () => selectedUploadIds.value.length != 0 && !selectedUploadsQuery.loading.value,
)
</script>

<template>
  <v-dialog
    :model-value="props.modelValue"
    max-width="1400"
    scrollable
    @update:model-value="(v) => emit('update:modelValue', v)"
    @close="emit('update:modelValue', false)"
  >
    <v-card>
      <v-card-title>{{ t('component.uploadPicker.title') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <text-field
              v-model="searchQuery.nameContains"
              :label="t('component.uploadPicker.search')"
            />
          </v-col>
          <v-col offset="1" cols="4" class="mt-3">
            <upload-button
              :mime-types="props.mimeTypes"
              @new-upload-ids="(v) => justUploadedIds.push(...v)"
            />
          </v-col>
        </v-row>

        <v-data-table
          v-model="selectedUploadIds"
          density="compact"
          :headers="headers"
          :items="items"
          item-value="id"
          :loading="fetchQuery.loading.value"
          show-select
          item-selectable="isSelectable"
          :select-strategy="props.pickOne ? 'single' : 'page'"
          :no-data-text="t('component.uploadPicker.tableNoData')"
          :items-per-page-text="t('component.uploadPicker.tableItemsPerPage')"
          @click:row="onRowClick"
        >
          <template #[`item.createdAt`]="{ value }">
            <date-time v-if="value" :model-value="value" />
            <v-progress-linear v-if="!value" indeterminate />
          </template>
          <template v-if="items.length >= 50" #[`footer.prepend`]>
            <v-alert type="info" density="compact" class="my-3">
              {{ t('component.uploadPicker.maybeMoreItems') }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$emit('update:modelValue', false)">
          {{ t('component.uploadPicker.closeButton') }}
        </v-btn>
        <v-btn
          variant="elevated"
          color="primary"
          :disabled="!canSelectUploads"
          @click="selectUploads()"
        >
          {{ t('component.uploadPicker.selectButton') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
