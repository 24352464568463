<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { Language } from '@/generated/graphql'
import SelectField from '@/components/input/SelectField.vue'

const model = defineModel<Language>({ required: false })

const { t } = useI18n()

const items = Object.values(Language).map((l) => ({
  title: t(`language.${l}`),
  value: l,
}))
</script>

<template>
  <select-field v-model="model" :items="items" />
</template>

<style scoped lang="scss"></style>
