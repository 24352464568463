<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  Article,
  CreateIssueLinkDialogArticleMutation,
  CreateIssueLinkDialogArticleMutationVariables,
  CreateIssueLinkDialogBopMutation,
  CreateIssueLinkDialogBopMutationVariables,
  CreateIssueLinkDialogModuleMutation,
  CreateIssueLinkDialogModuleMutationVariables,
  CreateIssueLinkDialogSerialNumberMutation,
  CreateIssueLinkDialogSerialNumberMutationVariables,
  CreateIssueLinkDialogStepMutation,
  CreateIssueLinkDialogStepMutationVariables,
  IssueLink,
  LinkIssueToArticle,
  LinkIssueToBop,
  LinkIssueToModule,
  LinkIssueToSerialNumber,
  LinkIssueToStep,
  Step,
} from '@/generated/graphql'
import { computed, ref, watch } from 'vue'
import { ComponentExposed } from 'vue-component-type-helpers'
import UpsertDialog from '@/components/dialogs/UpsertDialog.vue'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import StepPicker from '@/components/input/StepPicker.vue'
import ProductConfigurationPicker from '@/components/input/ProductConfigurationPicker.vue'
import BopPicker from '@/components/input/BopPicker.vue'
import ModulePicker from '@/components/input/ModulePicker.vue'
import SitePicker from '@/components/input/SitePicker.vue'
import ProductPicker from '@/components/input/ProductPicker.vue'
import { required } from '@/validation'
import useVuelidate from '@vuelidate/core'
import ArticlePicker from '@/components/input/ArticlePicker.vue'
import TextField from '@/components/input/TextField.vue'
import { issueRefetchQueries } from '@/components/issue/issueRefetchQueries'

type LinkType = NonNullable<IssueLink['__typename']>

const { t } = useI18n()

const createDialog = ref<ComponentExposed<typeof UpsertDialog<() => Promise<unknown>>>>()
const model = ref<Record<string, string | undefined>>({})

const selectedProductId = ref<string>()
const selectedConfigId = ref<string>()
const selectedSiteId = ref<string>()
const selectedBopId = ref<string>()
const selectedModuleId = ref<string>()
const selectedStepId = ref<string>()
const selectedArticle = ref<Article>()

watch(selectedBopId, (v) => (model.value.bopId = v))
watch(selectedModuleId, (v) => (model.value.moduleId = v))
watch(selectedStepId, (v) => (model.value.stepId = v))
watch(selectedArticle, (v) => (model.value.articleId = v?.id))

const bopMutation = useMutation<
  CreateIssueLinkDialogBopMutation,
  CreateIssueLinkDialogBopMutationVariables
>(
  gql`
    mutation CreateIssueLinkDialogBOP($command: LinkIssueToBOP!) {
      issue {
        linkIssueToBOP(command: $command) {
          id
        }
      }
    }
  `,
  { refetchQueries: issueRefetchQueries },
)
const moduleMutation = useMutation<
  CreateIssueLinkDialogModuleMutation,
  CreateIssueLinkDialogModuleMutationVariables
>(
  gql`
    mutation CreateIssueLinkDialogModule($command: LinkIssueToModule!) {
      issue {
        linkIssueToModule(command: $command) {
          id
        }
      }
    }
  `,
  { refetchQueries: issueRefetchQueries },
)
const stepMutation = useMutation<
  CreateIssueLinkDialogStepMutation,
  CreateIssueLinkDialogStepMutationVariables
>(
  gql`
    mutation CreateIssueLinkDialogStep($command: LinkIssueToStep!) {
      issue {
        linkIssueToStep(command: $command) {
          id
        }
      }
    }
  `,
  { refetchQueries: issueRefetchQueries },
)
const serialNumberMutation = useMutation<
  CreateIssueLinkDialogSerialNumberMutation,
  CreateIssueLinkDialogSerialNumberMutationVariables
>(
  gql`
    mutation CreateIssueLinkDialogSerialNumber($command: LinkIssueToSerialNumber!) {
      issue {
        linkIssueToSerialNumber(command: $command) {
          id
        }
      }
    }
  `,
  { refetchQueries: issueRefetchQueries },
)
const articleMutation = useMutation<
  CreateIssueLinkDialogArticleMutation,
  CreateIssueLinkDialogArticleMutationVariables
>(
  gql`
    mutation CreateIssueLinkDialogArticle($command: LinkIssueToArticle!) {
      issue {
        linkIssueToArticle(command: $command) {
          id
        }
      }
    }
  `,
  { refetchQueries: issueRefetchQueries },
)

const type = ref<LinkType>('BOPIssueLink')

const open = async (initType: LinkType, initIssueId: string) => {
  type.value = initType
  model.value = {
    issueId: initIssueId,
  }
  selectedProductId.value = undefined
  selectedConfigId.value = undefined
  selectedSiteId.value = undefined
  selectedBopId.value = undefined
  selectedModuleId.value = undefined
  selectedStepId.value = undefined
  selectedArticle.value = undefined

  switch (initType) {
    case 'ModuleIssueLink':
      return createDialog.value?.open(() =>
        moduleMutation.mutate({ command: model.value as unknown as LinkIssueToModule }),
      )
    case 'StepIssueLink':
      return createDialog.value?.open(() =>
        stepMutation.mutate({ command: model.value as unknown as LinkIssueToStep }),
      )
    case 'SerialNumberIssueLink':
      return createDialog.value?.open(() =>
        serialNumberMutation.mutate({ command: model.value as unknown as LinkIssueToSerialNumber }),
      )
    case 'ArticleIssueLink':
      return createDialog.value?.open(() =>
        articleMutation.mutate({ command: model.value as unknown as LinkIssueToArticle }),
      )
    default:
      return createDialog.value?.open(() =>
        bopMutation.mutate({ command: model.value as unknown as LinkIssueToBop }),
      )
  }
}
defineExpose({ open })

const validationRules = computed(() => {
  switch (type.value) {
    case 'BOPIssueLink':
      return { bopId: { required } }
    case 'ModuleIssueLink':
      return { moduleId: { required } }
    case 'StepIssueLink':
      return { stepId: { required } }
    case 'SerialNumberIssueLink':
      return { stepId: { required }, poNumber: { required }, serialNumber: { required } }
    case 'ArticleIssueLink':
      return { articleId: { required } }
    default:
      return {}
  }
})
const validation = useVuelidate(validationRules, model)

const needsBOP = computed(() => type.value != 'ArticleIssueLink')
const needsModule = computed(() => !['ArticleIssueLink', 'BOPIssueLink'].includes(type.value))
const needsStep = computed(
  () => !['ArticleIssueLink', 'BOPIssueLink', 'ModuleIssueLink'].includes(type.value),
)
const needsSerialNumber = computed(() => type.value == 'SerialNumberIssueLink')
</script>

<template>
  <upsert-dialog ref="createDialog" type="create" :validation="validation">
    <template v-if="type == 'ArticleIssueLink'">
      <article-picker v-model="selectedArticle" />
    </template>
    <template v-if="needsBOP">
      <product-picker v-model="selectedProductId" :label="t('entity.product.singular')" required />
      <product-configuration-picker
        v-model="selectedConfigId"
        :product-id="selectedProductId"
        :label="t('entity.productConfiguration.singular')"
        required
      />
      <site-picker
        v-model="selectedSiteId"
        :label="t('entity.site.singular')"
        required
      />
      <bop-picker
        v-model="selectedBopId"
        :product-configuration-id="selectedConfigId"
        :site-id="selectedSiteId"
        :label="t('entity.issueLink.field.bopId')"
        required
      />
      <module-picker
        v-if="needsModule"
        v-model="selectedModuleId"
        :bop-id="selectedBopId"
        :label="t('entity.issueLink.field.moduleId')"
        required
      />
      <step-picker
        v-if="needsStep"
        v-model="selectedStepId"
        :module-id="selectedModuleId"
        :label="t('entity.issueLink.field.stepId')"
        required
      />
      <text-field
        v-if="selectedStepId && needsSerialNumber"
        v-model="model.serialNumber"
        :label="t('entity.issueLink.field.serialNumber')"
        required
      />
      <text-field
        v-if="selectedStepId && needsSerialNumber"
        v-model="model.poNumber"
        :label="t('entity.issueLink.field.poNumber')"
        required
      />
    </template>
  </upsert-dialog>
</template>

<style scoped lang="scss"></style>
