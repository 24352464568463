<script setup lang="ts" generic="T, MM">
import { Query } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed, watch } from 'vue'
import { itemSortByName } from '@/app'
import { useI18n } from 'vue-i18n'

const model = defineModel<string | undefined>()
const props = defineProps<{
  bopId?: string
  label?: string
}>()

const { t } = useI18n()

const label = computed(() => props.label || t('entity.module.singular'))

watch(
  () => props.bopId,
  () => {
    model.value = undefined
  },
)
const disabled = computed(() => !props.bopId)

const fetchQuery = useQuery<Query>(
  gql`
    query ModulePickerData($bopId: ID!) {
      product {
        bop(id: $bopId) {
          nodes {
            module {
              id
              name
              description
            }
          }
        }
      }
    }
  `,
  () => ({
    bopId: props.bopId as string,
  }),
  () => ({
    enabled: !disabled.value,
  }),
)
const options = computed<SelectOption<string>[]>(() =>
  disabled.value
    ? []
    : itemSortByName(fetchQuery.result.value?.product.bop.nodes, (n) => n.module.name).map((n) => ({
        title: n.module.name,
        value: n.module.id,
      })),
)
</script>

<template>
  <select-field
    v-model="model"
    :label="label"
    :loading="fetchQuery.loading.value"
    :items="options"
    :disabled="disabled"
  />
</template>

<style scoped lang="scss"></style>
