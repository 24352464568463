<script setup lang="ts" generic="T, MM">
import { BopPickerDataQuery, BopPickerDataQueryVariables } from '@/generated/graphql'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import SelectField, { SelectOption } from '@/components/input/SelectField.vue'
import { computed, watch } from 'vue'
import { itemSortByNumber } from '@/app'
import { useI18n } from 'vue-i18n'

const model = defineModel<string | undefined>()
const props = defineProps<{
  productConfigurationId?: string
  siteId?: string
  label?: string
}>()

const { t } = useI18n()

const label = computed(() => props.label || t('entity.bop.singular'))

const disabled = computed(() => !props.siteId || !props.productConfigurationId)
watch(
  () => [props.productConfigurationId, props.siteId],
  () => {
    model.value = undefined
  },
)

const fetchQuery = useQuery<BopPickerDataQuery, BopPickerDataQueryVariables>(
  gql`
    query BopPickerData($productConfigurationId: ID!, $siteId: ID!) {
      product {
        productConfiguration(id: $productConfigurationId) {
          bopsAtSite(siteId: $siteId) {
            id
            version
            versionStatus
          }
        }
      }
    }
  `,
  () => ({
    productConfigurationId: props.productConfigurationId as string,
    siteId: props.siteId as string,
  }),
  () => ({
    enabled: !disabled.value,
  }),
)
const options = computed<SelectOption<string>[]>(() =>
  disabled.value
    ? []
    : itemSortByNumber(
        fetchQuery.result.value?.product.productConfiguration.bopsAtSite,
        (c) => 100000 - c.version,
      ).map((b) => ({
        title: `${b.version} (${t(`component.versionStatus.status.${b.versionStatus}`)})`,
        value: b.id,
      })),
)
</script>

<template>
  <select-field
    v-model="model"
    :label="label"
    :items="options"
    :loading="fetchQuery.loading.value"
    :disabled="disabled"
  />
</template>

<style scoped lang="scss"></style>
