import * as validators from '@vuelidate/validators'
import { helpers } from '@vuelidate/validators'
import { i18n } from '@/i18n'
import { computed, Ref, unref } from 'vue'

export const classNameForInvalidFields = 'form-field-invalid'
export function scrollToFirstInvalidField() {
  const elementsInErrors = document.getElementsByClassName(classNameForInvalidFields)
  if (elementsInErrors && elementsInErrors.length > 0) {
    elementsInErrors[0].scrollIntoView({ behavior: 'smooth' })
  }
}

const { t } = i18n.global

// Translated core validation rules
export const required = validators.required
export const minLength = validators.minLength
export const maxLength = validators.maxLength
export const email = validators.email
export const maxValue = validators.maxValue
export const minValue = validators.minValue

// Custom validation rules
export const normalName = helpers.withMessage(
  () => t('validation.normalName.regex'),
  helpers.regex(/^.{1,150}$/),
)
export const normalDescription = helpers.withMessage(
  () => t('validation.normalDescription.regex'),
  helpers.regex(/^.{0,255}$/),
)
export const abbreviation = helpers.withMessage(
  () => t('validation.abbreviation.regex'),
  helpers.regex(/^.{1,5}$/),
)
export function unique(taken: Ref<string[]>) {
  const lowercased = computed(() => taken.value.map((v) => v.toLowerCase()))
  return helpers.withMessage(
    () => t('validation.unique.unique'),
    (value: string) => {
      return !lowercased.value.includes(value.toLowerCase())
    },
  )
}
export const fileName = helpers.withMessage(
  () => t('validation.filename.regex'),
  helpers.regex(/^[a-zA-Z0-9-_+. ]+\.[a-zA-Z0-9_]+$/),
)
export const isChecked = helpers.withMessage(
  () => t('validation.isChecked.notChecked'),
  (value: boolean | undefined) => !!value,
)
export const minMilliQuantity = (minMilliQuantity: number) =>
  helpers.withMessage(
    () => t('validation.milliQuantity.min', { min: minMilliQuantity / 1000 }),
    minValue(minMilliQuantity),
  )
export const maxMilliQuantity = (maxMilliQuantity: number) =>
  helpers.withMessage(
    () => t('validation.milliQuantity.max', { max: maxMilliQuantity / 1000 }),
    maxValue(maxMilliQuantity),
  )
export function maxDifference(
  maxDifference: Ref<number> | number,
  lowestField: Ref<number> | number,
) {
  return helpers.withMessage(
    () => t('validation.maxDifference.message', {difference: maxDifference}),
    (value: number) => {
      const diff = value - unref(lowestField)
      return diff <= unref(maxDifference)
    },
  )
}
