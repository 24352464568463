<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
  Language,
  StepProposalResult,
  StepProposalsFromMediaMutation,
  StepProposalsFromMediaMutationVariables,
} from '@/generated/graphql'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { reactive, ref } from 'vue'
import { v4 } from 'uuid'
import LanguagePicker from '@/components/input/LanguagePicker.vue'
import { PickedUpload } from '@/components/upload/UploadPicker.vue'
import StepProposalsProgress from '@/components/step/StepProposalsProgress.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@/validation'

const props = defineProps<{
  upload: PickedUpload
}>()
const emit = defineEmits<{
  result: [value: StepProposalResult]
}>()
const { t } = useI18n()

type Model = {
  language?: Language
}
const model = reactive<Model>({})
const validation = useVuelidate<Model>(
  {
    language: { required },
  },
  model,
)

const mutation = useMutation<
  StepProposalsFromMediaMutation,
  StepProposalsFromMediaMutationVariables
>(gql`
  mutation StepProposalsFromMedia($command: CreateStepProposalsFromMedia!) {
    product {
      createStepProposalsFromMedia(command: $command) {
        errors
        proposals {
          id
          name
          instructionText
          durationInSeconds
        }
      }
    }
  }
`)

const loadingRequestId = ref<string>()
const doProposals = () => {
  validation.value.$touch()
  if (validation.value.$invalid) {
    return
  }

  loadingRequestId.value = v4()
  mutation
    .mutate({
      command: {
        id: loadingRequestId.value as string,
        fromUploadId: props.upload.id,
        inLanguage: model.language as Language,
      },
    })
    .then((response) => {
      emit('result', response?.data?.product.createStepProposalsFromMedia as StepProposalResult)
    })
    .catch(() => {
      setTimeout(() => (loadingRequestId.value = undefined), 3_000)
    })
}
</script>

<template>
  <template v-if="!loadingRequestId">
    <language-picker
      v-model="model.language"
      :validation="validation.language"
      :label="t('component.stepProposalsFromMediaLoader.inputLanguage')"
    />
    <div class="text-center">
      <v-btn color="primary" class="mt-5" :loading="mutation.loading.value" @click="doProposals">
        {{ t('component.stepProposalsFromFileDialog.buttonCreateProposals') }}
      </v-btn>
    </div>
  </template>

  <step-proposals-progress v-if="!!loadingRequestId" :id="loadingRequestId" />
</template>

<style scoped lang="scss"></style>
